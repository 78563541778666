<template>
  <div class="box">
    <div class="has-margin-bottom">
      <label class="checkbox has-text-weight-bold">
        <input
          type="checkbox"
          @change="updateYieldPlayground"
          v-model="yieldSettingsPlayground.ApplyDayPercentage"
        />
        Day utilization
      </label>

      <span class="has-margin-left">
        <a @click="setShowInfo">
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'info-circle']" />
          </span>
        </a>
      </span>
    </div>

    <div v-if="showInfo">
      <article class="message is-info is-small">
        <div class="message-body"></div>
      </article>
    </div>

    <div
      v-if="yieldSettingsPlayground.ApplyDayPercentage && mShowBox && !showInfo"
    >
      <table class="table is-fullwidth is-narrow is-striped">
        <tr>
          <tbody>
            <tr
              v-for="(dayPercentage,
              index) in yieldSettingsPlayground.DayPercentages"
              :key="index"
            >
              <td width="225">
                <span class="is-size-7">{{
                  dayPercentage.Day | getDayName
                }}</span>
              </td>
              <td>
                <div class="field has-addons">
                  <p class="control">
                    <input
                      type="text"
                      v-model.number="dayPercentage.Percentage"
                      @change="dayPercentageChanged"
                      class="input is-small"
                    />
                  </p>
                  <p class="control">
                    <a class="button is-small is-static">%</a>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </tr>
      </table>
    </div>

    <div
      v-if="yieldSettingsPlayground.ApplyDayPercentage"
      class="has-text-centered has-margin-top"
    >
      <a @click="mShowBox = !mShowBox" class="button is-small is-rounded">
        <span v-if="mShowBox" class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-up']" />
        </span>
        <span v-if="!mShowBox" class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-down']" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  props: {
    showBox: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      mShowBox: true,
      showInfo: false
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsPlayground'])
  },

  watch: {
    showBox: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.mShowBox = newVal
      }
    }
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsPlayground']),

    setShowInfo() {
      this.showInfo = !this.showInfo
      if (this.showInfo) {
        this.mShowBox = true
      }
    },

    dayPercentageChanged() {
      this.updateYieldPlayground()
    },

    updateYieldPlayground() {
      this.setYieldSettingsPlayground(this.yieldSettingsPlayground)
      this.$emit('yieldSettingsUpdated')
    }
  }
}
</script>

<style></style>
